import { CallHistory } from "../interface";

export const callHistories: CallHistory[] = [
  {
    callId: "call001",
    userId: "user1",
    callType: "video",
    timestamp: new Date("2024-08-01T10:00:00Z"),
    duration: 300,
    participants: ["user1", "user2"],
    callDetails: {
      resolution: "720p",
      callQuality: "good",
    },
  },
  {
    callId: "call002",
    userId: "user2",
    callType: "audio",
    timestamp: new Date("2024-08-01T11:00:00Z"),
    duration: 120,
  },
  {
    callId: "call003",
    userId: "user3",
    callType: "chat",
    timestamp: new Date("2024-08-01T12:00:00Z"),
    messageContent: "Meeting at 2 PM?",
  },
  {
    callId: "call004",
    userId: "user4",
    callType: "video",
    timestamp: new Date("2024-08-02T09:30:00Z"),
    duration: 180,
    participants: ["user1", "user4"],
    callDetails: {
      resolution: "1080p",
      callQuality: "excellent",
    },
  },
  {
    callId: "call005",
    userId: "user1",
    callType: "audio",
    timestamp: new Date("2024-08-02T10:45:00Z"),
    duration: 90,
  },
  {
    callId: "call006",
    userId: "user5",
    callType: "chat",
    timestamp: new Date("2024-08-02T11:15:00Z"),
    messageContent: "Can you send the report?",
  },
  {
    callId: "call007",
    userId: "user2",
    callType: "video",
    timestamp: new Date("2024-08-03T08:00:00Z"),
    duration: 240,
    participants: ["user2", "user3"],
    callDetails: {
      resolution: "480p",
      callQuality: "fair",
    },
  },
  {
    callId: "call008",
    userId: "user6",
    callType: "audio",
    timestamp: new Date("2024-08-03T09:30:00Z"),
    duration: 150,
  },
  {
    callId: "call009",
    userId: "user7",
    callType: "chat",
    timestamp: new Date("2024-08-03T10:00:00Z"),
    messageContent: "I will review it by EOD.",
  },
  {
    callId: "call010",
    userId: "user8",
    callType: "video",
    timestamp: new Date("2024-08-04T14:00:00Z"),
    duration: 360,
    participants: ["user1", "user8"],
    callDetails: {
      resolution: "4K",
      callQuality: "excellent",
    },
  },
  {
    callId: "call011",
    userId: "user9",
    callType: "audio",
    timestamp: new Date("2024-08-04T15:00:00Z"),
    duration: 60,
  },
  {
    callId: "call012",
    userId: "user10",
    callType: "chat",
    timestamp: new Date("2024-08-04T16:30:00Z"),
    messageContent: "Please confirm the schedule.",
  },
  {
    callId: "call013",
    userId: "user3",
    callType: "video",
    timestamp: new Date("2024-08-05T11:00:00Z"),
    duration: 300,
    participants: ["user3", "user4"],
    callDetails: {
      resolution: "1080p",
      callQuality: "good",
    },
  },
  {
    callId: "call014",
    userId: "user4",
    callType: "audio",
    timestamp: new Date("2024-08-05T12:00:00Z"),
    duration: 200,
  },
  {
    callId: "call015",
    userId: "user5",
    callType: "chat",
    timestamp: new Date("2024-08-05T13:45:00Z"),
    messageContent: "Do you have the latest draft?",
  },
  {
    callId: "call016",
    userId: "user6",
    callType: "video",
    timestamp: new Date("2024-08-06T10:00:00Z"),
    duration: 120,
    participants: ["user2", "user6"],
    callDetails: {
      resolution: "720p",
      callQuality: "fair",
    },
  },
  {
    callId: "call017",
    userId: "user7",
    callType: "audio",
    timestamp: new Date("2024-08-06T11:30:00Z"),
    duration: 90,
  },
  {
    callId: "call018",
    userId: "user8",
    callType: "chat",
    timestamp: new Date("2024-08-06T12:00:00Z"),
    messageContent: "Thanks for the update!",
  },
  {
    callId: "call019",
    userId: "user9",
    callType: "video",
    timestamp: new Date("2024-08-07T09:00:00Z"),
    duration: 300,
    participants: ["user1", "user9"],
    callDetails: {
      resolution: "1080p",
      callQuality: "excellent",
    },
  },
  {
    callId: "call020",
    userId: "user10",
    callType: "audio",
    timestamp: new Date("2024-08-07T10:30:00Z"),
    duration: 75,
  },
];
