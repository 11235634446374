import React, { FC, SVGProps } from "react";

export const CallAndVideoIcon: FC<SVGProps<any>> = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width={width}
      height={height}
      viewBox="0 0 654.67004 360.00719"
    >
      <defs>
        <pattern
          id="e28a9de8-4fee-4e00-8e03-00a0f3e07003-38"
          data-name="New Pattern Swatch 2"
          width="26.47061"
          height="8.98619"
          patternTransform="translate(23.04015 21.15503) scale(0.61812)"
          patternUnits="userSpaceOnUse"
          viewBox="0 0 26.471 8.986"
        >
          <rect width="26.47061" height="8.98619" fill="none" />
          <path
            d="M26.32758,4.82817a2.86425,2.86425,0,0,1-2.6731,1.143,3.89439,3.89439,0,0,1-.65692-.13134.68688.68688,0,0,1-.09186.19928,2.8644,2.8644,0,0,1-2.67309,1.14306,6.39289,6.39289,0,0,1-3.01215-1.3504A5.07194,5.07194,0,0,0,13.5802,4.757,13.33824,13.33824,0,0,0,9.72443,6.03508c-.87323.39636-1.63483-.89679-.75708-1.29523A24.87795,24.87795,0,0,1,11.55359,3.6945,9.33444,9.33444,0,0,1,9.64234,2.62083,5.07192,5.07192,0,0,0,6.00208,1.54606a13.34039,13.34039,0,0,0-3.85578,1.278c-.87316.39642-1.63482-.89673-.75708-1.29517C3.58918.53026,6.18659-.56783,8.606.33586a24.18048,24.18048,0,0,1,3.00641,1.77161c.65356.35675,1.87891.69641,2.42-.03638a.8048.8048,0,0,1,1.4184.27765A6.59563,6.59563,0,0,1,19.606,2.33586a24.18048,24.18048,0,0,1,3.00641,1.77161c.65356.35675,1.87891.69641,2.42-.03638C25.60028,3.30211,26.90155,4.051,26.32758,4.82817Zm-4.6872.42676c-.33764-.19684-.66931-.40894-.998-.6341a5.07192,5.07192,0,0,0-3.64026-1.07477c-.197.02332-.39.07117-.58435.11048.99811.43329,1.81952,1.14148,2.77276,1.66186.65351.35675,1.87891.69641,2.42-.03637C21.61927,5.27019,21.63129,5.266,21.64038,5.25493Z"
            opacity="0.1"
          />
          <path
            d="M13.03241,7.07109c-.54113.73279-1.76648.39313-2.42.03638A24.18048,24.18048,0,0,0,7.606,5.33586c-2.41937-.90369-5.01678.1944-7.21674,1.193-.87774.39844-.11608,1.69159.75708,1.29517a13.34039,13.34039,0,0,1,3.85578-1.278A5.07192,5.07192,0,0,1,8.64234,7.62083a6.39218,6.39218,0,0,0,3.01214,1.35034,2.86425,2.86425,0,0,0,2.6731-1.143C14.90155,7.051,13.60028,6.30211,13.03241,7.07109Z"
            opacity="0.1"
          />
        </pattern>
      </defs>
      <path
        d="M828.3298,509h-173.748a44.626,44.626,0,0,0-44.626,44.626V628h14V586.96057a44.626,44.626,0,0,1,44.626-44.626h145.748a44.626,44.626,0,0,1,44.626,44.626V628h14V553.626A44.626,44.626,0,0,0,828.3298,509Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <ellipse cx="506.79079" cy="244.5036" rx="45.5" ry="6.5" fill="#e6e6e6" />
      <path
        d="M813.85249,516.12982H753.2482c-14.96069-14.33791-25.65758-28.156-17.09351-39.62591a12.43164,12.43164,0,0,1,12.43163-12.43163h69.928A12.43166,12.43166,0,0,1,830.946,476.50391C834.20664,489.57329,828.38139,502.7839,813.85249,516.12982Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M802.96847,448.42205a2.80741,2.80741,0,0,1-2.03464-4.86593l.19236-.76477q-.038-.09188-.07643-.18358a7.53909,7.53909,0,0,0-13.90673.05168c-2.27452,5.4781-5.17038,10.96553-5.88341,16.7577a22.30361,22.30361,0,0,0,.39153,7.67061A89.4194,89.4194,0,0,1,773.517,429.949a86.307,86.307,0,0,1,.53535-9.62829q.44349-3.93128,1.23055-7.80653A90.45557,90.45557,0,0,1,793.221,374.17689a24.07273,24.07273,0,0,0,10.0118-10.38735,18.36254,18.36254,0,0,0,1.67-5.0179c-.48738.06393-1.83778-7.3591-1.47018-7.81452-.67923-1.03071-1.895-1.54307-2.6368-2.54885-3.68943-5.00211-8.77253-4.12872-11.42612,2.66875-5.6687,2.861-5.72357,7.60578-2.24528,12.16916,2.2129,2.90325,2.51695,6.83168,4.45862,9.93984-.19981.25572-.40756.50345-.6073.75916a91.04013,91.04013,0,0,0-9.50223,15.05379,37.84589,37.84589,0,0,0-2.25945-17.5787c-2.16269-5.21725-6.21632-9.61123-9.786-14.12153-4.2878-5.41757-13.08033-3.05324-13.83575,3.81438q-.011.09975-.02142.19942.79533.44867,1.55706.95247a3.808,3.808,0,0,1-1.53532,6.92992l-.07765.012a37.88983,37.88983,0,0,0,.99876,5.66515c-4.57949,17.71009,5.30731,24.16047,19.42432,24.45023.31163.15979.61531.31957.92694.47144a92.92556,92.92556,0,0,0-5.00193,23.53931,88.13663,88.13663,0,0,0,.06393,14.23066l-.024-.16778a23.28917,23.28917,0,0,0-7.95036-13.44763c-6.11822-5.0259-14.76211-6.87666-21.36259-10.9165a4.37072,4.37072,0,0,0-6.69422,4.252q.01327.08819.027.17642a25.57816,25.57816,0,0,1,2.86849,1.38225q.79532.44877,1.55706.95247a3.80809,3.80809,0,0,1-1.53533,6.93l-.07772.01194c-.05591.008-.10387.016-.15972.024a37.92262,37.92262,0,0,0,6.97545,10.92265c2.86344,15.46021,15.162,16.927,28.31753,12.42487h.008a92.897,92.897,0,0,0,6.24043,18.21782h22.2928c.08-.24769.15184-.50341.22379-.75114a25.32954,25.32954,0,0,1-6.16856-.36744c1.654-2.02958,3.308-4.07513,4.962-6.10463a1.38552,1.38552,0,0,0,.10388-.11987c.839-1.03867,1.68595-2.06946,2.52491-3.10816l.00045-.00127a37.1011,37.1011,0,0,0-1.08711-9.45126Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <polygon
        points="353.657 245.975 361.653 238.365 336.106 203.905 324.306 215.136 353.657 245.975"
        fill="#ffb6b6"
      />
      <path
        d="M618.50115,512.97847l12.0477-12.91964,5.20869,5.47265L655.884,502.3603a4.71924,4.71924,0,0,1,3.98845,8.08024l-15.20713,14.47365-7.77337-2.91853,4.12876,6.38736-5.7337,5.45715Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M624.95577,490l-11,15c-9.31-4.96-39.02416-24.64132-61.78417-34.95132-14.71-6.68-26.06-11.76-26.06-11.76s-1.53,2.17-3.97,5.58c-8.93,12.5-30.02,41.59-32.03,40.51-2.55-1.37006-5.44,1.08-5.08,6.1.26,3.66-5.93,6.33-9.31,7.52-1.27.44-2.14.68-2.14.68-.57995.37-1.16.74-1.73,1.09-18.51,11.31-40.2,8.12-55.1-4a48.00894,48.00894,0,0,1-17.83-35.53l-1.33-31,45-2,8.01,15.21,14.21-11.58,31.11-25.35,11.36-9.26s-.3-.04.06-1.4a27.17547,27.17547,0,0,1,4.45-1.92q.39-.135.78-.24c14.65-4.32,25.76,6.92,25.76,6.92S606.28579,465.57,608.95577,466c2.67.44,4.20582,4.72867,4.52583,5.74869.31.96,4.5642,6.38131,6.47417,6.25131C625.91579,477.61,624.95577,490,624.95577,490Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M490.05254,397.16165a8.97955,8.97955,0,0,0-5.54,5.267l-41.26383-.227-21.95028-30.36164-16.31606,7.22367,26.55547,48.11507,58.13156-12.96842-.03425-.12144a8.99965,8.99965,0,1,0,.41738-16.92732Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ffb6b6"
      />
      <path
        d="M413.589,351.151l-.85679.12554a17.3037,17.3037,0,0,0-14.68482,19.23291c.66275,5.30918,1.04857,10.06062.46825,11.00561-1.42848,2.32612-1.97471,4.27522.8277,4.56664s-3.03837-1.42413,5.89008.06861,7.3493-3.25133,14.62267-3.56452,12.181,1.56362,14.4719-2.53508,8.67943-4.26671,5.17068-8.2938-4.45889-.549-7.30137-9.08831q-.04263-.1281-.08552-.255A16.93484,16.93484,0,0,0,413.589,351.151Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#d45561"
      />
      <path
        d="M389.49778,340.55061a33.22205,33.22205,0,0,1-3.38,2.88q-.525.405-1.05.75c-10.15,6.87-20.17,3.93-27.98-3.24-12.07-11.1-18.85-32.33-12.59-43.11,14.02-17.1,32.22-13.43006,43.28-1.81C398.5178,307.2906,402.53782,326.05061,389.49778,340.55061Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M433.56161,354.78867c-.86-.55-1.72-1.08-2.6-1.59q-.58494-.795-1.2-1.56Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M409.04159,309.42869c13.31745,10.13064,28.14844,18.1933,30.944,29.3421,0,0-1.67456-17.51718,6.13678-14.1969,13.12494,5.57886,8.68409-24.76108,5.00354-29.72229-6.13424-8.26868-26.81922-12.967-26.81922-12.967l-14.67387,8.05529Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M470.36907,625.839a3.96822,3.96822,0,0,0,3.82405,2.67859l16.3843-.42166a3.914,3.914,0,0,0,3.699-2.88019l2.31856-24.7926a6.057,6.057,0,0,0,2.621.53855,5.97521,5.97521,0,1,0-.29989-11.94665,5.06191,5.06191,0,0,0-1.07377.11167,4.04954,4.04954,0,0,0-3.01444-1.30066l-25.20282.65081a3.61975,3.61975,0,0,0-.62168.07718,3.93627,3.93627,0,0,0-3.0139,5.1114Zm26.70134-27.09924,1.64984-5.90805a4.024,4.024,0,0,0,.01259-2.08706c.08006-.00022.15068-.03816.23073-.03837a4.27391,4.27391,0,0,1,.20012,8.54544A4.02328,4.02328,0,0,1,497.07041,598.73977Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ccc"
      />
      <path
        d="M469.00335,588.55405c.53694,3.07014,6.57374,5.22844,13.83253,4.85329,6.67493-.30754,12.13274-2.65139,13.13264-5.42921a3.72651,3.72651,0,0,0-1.14067-.15207l-25.20282.65081A3.61975,3.61975,0,0,0,469.00335,588.55405Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#fff"
      />
      <circle cx="159.56625" cy="28.72589" r="23" fill="#ffb6b6" />
      <path
        d="M423.948,289.922l-.885,3.88735,15.47168,3.52222a5.70581,5.70581,0,0,1,1.90257-4.15451,9.72128,9.72128,0,0,0,.111,4.61293c8.344,4.68212,7.86452,6.34089,14.60818,5.14689a3.533,3.533,0,0,0,4.22909-2.6606v0c2.71333-11.91851,3.60488-24.10007-8.25519-27.05845A22.32371,22.32371,0,0,0,423.948,289.922Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M541.396,434.52941a.98062.98062,0,0,0-.18816-.17287,2.01234,2.01234,0,0,0-.61614-.48219l-1.6832-.90095L527.7352,420.5685a2.80646,2.80646,0,0,0-2.09816-.2007l-.02874.0087-4.78642,1.48028-4.11644,1.27747-5.75023,1.78243-39.16614,12.12769-25.7448,7.97084a2.785,2.785,0,0,0-1.8295,3.448,2.89846,2.89846,0,0,0,.58784,1.04447l.81928.91173,1.71831,1.91427,3.98114,8.25041,1.10458,1.23285a2.775,2.775,0,0,0,2.86591.80413l84.81461-23.58471a2.82416,2.82416,0,0,0,1.28951-4.50691Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ccc"
      />
      <path
        d="M536.36046,311.824l-1.64243.2662a1.72823,1.72823,0,0,0-1.11942.69471l-.72375,1.01191-6.5272,16.32176a1.7281,1.7281,0,0,0-.29667,1.28843l.7647,4.65037L523.92541,419.063a1.74786,1.74786,0,0,0,.64654,1.084l12.67291,16.95222.29589.23506.63823.49974a1.75568,1.75568,0,0,0,1.14665.36459l1.07695-.05133a1.73016,1.73016,0,0,0,1.26549-.64526,1.70389,1.70389,0,0,0,.35943-1.36986L538.351,313.24413a1.72477,1.72477,0,0,0-1.98213-1.42151Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ccc"
      />
      <polygon
        points="260.945 48.277 255.163 65.891 252.927 136.244 261.927 144.244 260.945 48.277"
        fill="#d45561"
      />
      <path
        d="M418.77669,327.411l-7.39-3.46348-7.2761,12.6606s-19.54183,27.88665-19.8253,48.00476,3.18465,26.10071,3.18465,26.10071-8.42569,6.29921-4.21284,10.14961,13.53953,5.51181,6.37619,7.1811-5.348,18.56858,2.652,21.56858,50.96418,2.97375,50.96418,2.97375a7.65139,7.65139,0,0,1,1.88976-7.53446c3.33071-3.33858,6.33071-.9429,3.33071-6.14074s-8-2.19784-4-9.19784,3.99854-9.09184,3.99854-9.09184-2.14443-49.35733-14.42688-66.19308h0l.26161-.46311v0A27.10336,27.10336,0,0,0,418.77669,327.411Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#d45561"
      />
      <path
        d="M427.73233,305.43156a23.01077,23.01077,0,0,1,31.86316-15.87195,14.03243,14.03243,0,0,0-6.1388-7.57007l-17.4151-7.56085c-8.59021-1.95557-22.18841-7.50605-28.39306-2.19074-5.71656,4.88288-2.93427,14.441-8.19837,19.794-5.769,5.86642-6.259,12.803-4.62292,16.79272,1.89276,4.6157,5.67926,10.91291,10.4046,12.27588a6.98906,6.98906,0,0,1,2.2887-4.19665,11.96588,11.96588,0,0,0-.08728,4.70807l28.60833,22.81671c-2.67191-2.324-4.94828-8.31186-6.51613-15.05615-.928-3.99212-6.46517-5.39319-1.97452-12.16852S427.12255,308.11009,427.73233,305.43156Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M144.37662,265.43228h10a0,0,0,0,1,0,0v79a5,5,0,0,1-5,5h0a5,5,0,0,1-5-5v-79A0,0,0,0,1,144.37662,265.43228Z"
        fill="#f2f2f2"
      />
      <path
        d="M402.13162,349.10868"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M388.02061,627.65215l13.99253-18.55838c4.43974-5.88845,8.69674-12.01131,13.3873-17.69109,1.66663-2.01812,3.60239-4.10427,6.06747-4.71414a5.08986,5.08986,0,0,1,2.94051.12468,9.14162,9.14162,0,0,1,3.03744,1.77954,25.33672,25.33672,0,0,1,5.11873,6.62593c1.74674,3.03377,3.36151,6.14671,4.86527,9.30758a136.68885,136.68885,0,0,1,8.28138,22.37122c1.026,3.71722,6.81622,2.13878,5.78567-1.595a146.08681,146.08681,0,0,0-8.01709-21.942q-2.37674-5.191-5.17193-10.17637a35.86713,35.86713,0,0,0-5.8126-8.08111A14.79987,14.79987,0,0,0,423.7,580.6054c-3.60537-.39238-6.94928,1.23222-9.62618,3.51429-2.68922,2.29257-4.7096,5.32467-6.8215,8.1257l-7.44284,9.87148-16.96966,22.507c-.97615,1.29467-.20667,3.35419,1.07625,4.10457a3.07331,3.07331,0,0,0,4.10457-1.07625Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M484.04159,427.42869a8.97957,8.97957,0,0,0-6.91992,3.24774l-39.08008-13.24774L426.804,381.68851l-17.76239,1.70081,10,54.03937,59.252,6.05511.00586-.126a8.99965,8.99965,0,1,0,5.74218-15.92907Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ffb6b6"
      />
      <path
        d="M426.02337,359.62353l-.85259-.15148a17.3037,17.3037,0,0,0-20.00753,13.61055c-1.048,5.24675-2.18256,9.87684-3.03163,10.59018-2.09,1.75591-3.22388,3.43273-.657,4.59432s-2.43307-2.31085,5.56693,1.92537,8-.76378,15,1.23622,11.06364,5.33071,14.53182,2.16535,9.58274-1.30709,7.52546-6.23622-4.05728-1.92913-4.05728-10.92913q0-.135-.00059-.269A16.93486,16.93486,0,0,0,426.02337,359.62353Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#d45561"
      />
      <ellipse cx="428.79079" cy="253.5036" rx="39.5" ry="6.5" fill="#e6e6e6" />
      <path
        d="M741.16081,485.95967a39.39051,39.39051,0,0,1-9.71066,25.961,38.64591,38.64591,0,0,1-5.16128,4.945,39.23354,39.23354,0,0,1-9.32743,5.56307H686.241a39.23374,39.23374,0,0,1-9.32743-5.56307,38.64584,38.64584,0,0,1-5.16127-4.945,39.99685,39.99685,0,0,1-4.8028-6.8673,39.56229,39.56229,0,1,1,73.98265-23.35873A38.93522,38.93522,0,0,1,741.16081,485.95967Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#3f3d56"
      />
      <path
        d="M731.45015,511.92067a38.64591,38.64591,0,0,1-5.16128,4.945H676.91353a38.64584,38.64584,0,0,1-5.16127-4.945Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#d45561"
      />
      <path
        d="M740.93211,481.69464c-8.26425,12.826-23.47614,22.7777-41.995,25.80027a66.22457,66.22457,0,0,1-31.98765-2.44154,39.56229,39.56229,0,1,1,73.98265-23.35873Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#d45561"
      />
      <path
        d="M740.93211,481.69464c-8.26425,12.826-23.47614,22.7777-41.995,25.80027a66.22457,66.22457,0,0,1-31.98765-2.44154,39.56229,39.56229,0,1,1,73.98265-23.35873Z"
        transform="translate(-272.66498 -269.9964)"
        fill="url(#e28a9de8-4fee-4e00-8e03-00a0f3e07003-38)"
      />
      <polygon
        points="339.465 272.115 348.231 265.407 326.528 228.405 313.591 238.306 339.465 272.115"
        fill="#ffb6b6"
      />
      <path
        d="M604.67515,538.29691l13.364-11.55283,4.59163,5.99977,20.35052-.994a4.71923,4.71923,0,0,1,3.09873,8.46143l-16.67187,12.759-7.41548-3.73549,3.41981,6.79338-6.286,4.81065Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M613.55727,516.14335l-12.54548,13.73357c-8.72426-5.93-36.15592-28.685-57.67874-41.37682-13.90864-8.21929-24.64824-14.48743-24.64824-14.48743s-1.754,1.99335-4.54566,5.122c-10.21926,11.47-34.30782,38.13-36.19039,36.84067-2.38832-1.63567-5.52446.49022-5.705,5.51989-.13407,3.66674-6.57476,5.65743-10.06291,6.478-1.30988.30123-2.2006.44652-2.2006.44652-.6163.30565-1.23266.6113-1.83691.89812-19.61637,9.25931-40.83906,3.76118-54.353-9.88711a48.009,48.009,0,0,1-13.91611-37.23748l2.00281-30.96381,44.9549,2.83836,6.33234,15.98139,15.37014-9.98894L492.184,438.19345l12.2877-7.98808s-.294-.07193.20981-1.38545a27.17585,27.17585,0,0,1,4.63028-1.43159q.40225-.09243.80128-.155c15.02881-2.72362,24.86907,9.64321,24.86907,9.64321s62.6333,52.97517,65.24176,53.68907c2.60744.72386,3.67434,5.15252,3.88309,6.201.20524.98768,3.85339,6.83407,5.76629,6.90968C615.84074,503.92779,613.55727,516.14335,613.55727,516.14335Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#2f2e41"
      />
      <path
        d="M411.06779,551.33064c-9.41-20.14-78.03-.67005-82.54-44.68006-1.24-12.81,8.36-23.87994,17.48-32.96,64.87-44.86-61.79-78.01-12.58-122.3a116.22558,116.22558,0,0,1,23.66-10.45c11.39-3.35,19.89-2.73,26.33.77a23.72341,23.72341,0,0,1,2.7,1.72c28.22,20.85,11.15,105.09,40.77,131.25,11.28,8.9,61.17,26.14,69.18,39.58a10.75264,10.75264,0,0,1,.57,1.12c2.7413,5.27518-59.08753,19.64458-54,32C449.6378,564.38062,410.46326,589.16544,411.06779,551.33064Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
      <path
        d="M272.665,628.81359a1.18647,1.18647,0,0,0,1.19006,1.19h652.29a1.19,1.19,0,0,0,0-2.38H273.855A1.18651,1.18651,0,0,0,272.665,628.81359Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#ccc"
      />
      <path
        d="M465.6378,445.83561c0,6.35127,5.36281,18.545-26,18.545s-87.57481-12.19374-87.57481-18.545,25.42459-11.5,56.7874-11.5S465.6378,439.48433,465.6378,445.83561Z"
        transform="translate(-272.66498 -269.9964)"
        fill="#f2f2f2"
      />
    </svg>
  );
};
