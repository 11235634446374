import React, { FC, SVGProps } from "react";

export const AlterBuddyLogo: FC<SVGProps<any>> = ({ ...rest }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      height={35}
      y="0px"
      fill="currentColor"
      className="fill-primary-500"
      viewBox="0 0 919.18 165.18"
      {...rest}
    >
      <g>
        <g>
          <path className="st0" d="M162.99,8.22v115h-19.91v-115H162.99z" />
          <path
            className="st0"
            d="M208.89,19.25v23.03h22.38v17.28h-22.54V94.6c0,7.73,4.28,11.52,10.53,11.52c3.13,0,6.75-0.99,9.71-2.47
			l5.59,17.11c-5.76,2.3-10.53,3.29-16.62,3.45c-17.6,0.66-29.12-9.38-29.12-29.61V59.56h-15.14V42.28h15.14V21.39L208.89,19.25z"
          />
          <path
            className="st0"
            d="M263.35,89.83c1.32,10.04,10.04,17.28,24.18,17.28c7.4,0,17.11-2.8,21.72-7.57l12.83,12.67
			c-8.56,8.88-22.54,13.16-34.88,13.16c-27.97,0-44.59-17.27-44.59-43.27c0-24.68,16.78-42.45,43.1-42.45
			c27.15,0,44.09,16.78,40.97,50.18H263.35z M307.44,73.21c-1.32-10.53-9.54-15.79-21.06-15.79c-10.86,0-19.74,5.26-22.7,15.79
			H307.44z"
          />
          <path
            className="st0"
            d="M360.74,42.12l1.48,9.38c6.25-10.04,14.64-11.52,22.87-11.52c8.39,0,16.45,3.29,20.89,7.73l-9.05,17.44
			c-4.11-3.45-7.9-5.26-14.48-5.26c-10.53,0-20.24,5.59-20.24,20.56v42.78h-20.07V42.12H360.74z"
          />
          <path
            className="st0"
            d="M508.65,41.46c0,8.88-4.28,17.44-14.97,22.21c13.49,4.11,18.43,18.43,18.43,25.66
			c0,25.67-19.25,33.89-43.1,33.89c-18.26,0-36.36,0-54.62,0V8.06c18.1,0,36.36,0,54.62,0C491.7,8.06,508.65,18.26,508.65,41.46z
			 M435.76,54.29H469c13.66,0,18.1-5.76,18.1-12.34c0-5.26-4.28-14.15-18.1-14.15h-33.23V54.29z M435.76,102.99H469
			c8.88,0,21.55-2.96,21.55-13.82c0-10.36-12.67-15.79-21.55-15.79h-33.23V102.99z"
          />
          <path
            className="st0"
            d="M546.49,42.12v42.45c0,12.34,6.75,21.72,19.58,21.72c12.34,0,20.73-10.36,20.73-22.7V42.12h19.91v81.27
			h-17.93l-1.32-11.02c-8.39,8.23-16.12,12.18-27.47,12.18c-19.42,0-33.56-14.64-33.56-39.82V42.12H546.49z"
          />
          <path
            className="st0"
            d="M708.71,8.22v115h-18.76l-1.32-11.19c-6.25,9.71-16.29,13-26.16,13c-23.86,0-41.79-15.79-41.79-42.45
			c0-27.97,17.6-42.45,41.3-42.45c8.55,0,21.88,4.61,26.65,13V8.22H708.71z M640.76,82.59c0,14.15,10.2,23.86,23.03,23.86
			c12.67,0,23.53-9.21,23.53-23.86c0-14.15-10.86-23.69-23.53-23.69C650.96,58.9,640.76,67.95,640.76,82.59z"
          />
          <path
            className="st0"
            d="M810.38,8.22v115h-18.76l-1.32-11.19c-6.25,9.71-16.29,13-26.16,13c-23.86,0-41.79-15.79-41.79-42.45
			c0-27.97,17.6-42.45,41.3-42.45c8.55,0,21.88,4.61,26.65,13V8.22H810.38z M742.43,82.59c0,14.15,10.2,23.86,23.03,23.86
			c12.67,0,23.53-9.21,23.53-23.86c0-14.15-10.86-23.69-23.53-23.69C752.63,58.9,742.43,67.95,742.43,82.59z"
          />
          <path
            className="st0"
            d="M909.59,42.12l-49.52,115h-22.05l15.14-35.21l-32.41-79.79h23.36l12.34,35.04l7.73,22.87l8.39-22.37
			l14.81-35.54H909.59z"
          />
        </g>
        <g>
          <g>
            <path
              className="st0"
              d="M94.02,124.05h42.58L84.94,8.88H61.25L9.59,124.05h42.62c0-0.49,0-0.95,0-1.25c0-2.26,0-15.03,0-19.17v-11
				c0-5.5,0-11,0-16.5c0-3,0-6,0-9v-0.96c2.08,1.36,4.08,2.66,6.02,3.93c1.07,0.7,2.21,1.05,3.41,1.05c0.54,0,1.09-0.07,1.65-0.22
				l0.45-0.12c1.48-0.38,3.92-0.99,5.54-1.39l0.52-0.13c0.41-0.1,0.82-0.2,1.23-0.3v2.86l-0.01-0.01c0,15.14-0.01,30.29,0.01,45.43
				c0,0-0.01,0-0.01-0.01c0-0.41,0-0.83,0-1.24c0,2.67,0,5.34-0.01,8.02h4.22c0-2.2-0.01-5.49-0.01-7.57l0,0V78.99
				c0.01-0.07,0.02-0.15,0.02-0.23V67.68c0-1.4-1.48-1.64-2.42-1.38c-0.02,0-0.03,0.01-0.05,0.01c-0.35,0.09-0.7,0.17-1.05,0.25
				l-7.06,1.74c-0.54,0.14-1.05,0.27-1.48,0.38c-0.15,0.04-0.3,0.08-0.45,0.12c-1.2,0.31-2.27,0.13-3.3-0.55
				c-4.17-2.73-8.35-5.45-12.54-8.17c-0.97-0.63-1.66-1.43-1.85-2.59c-0.23-1.44,0.24-2.64,1.45-3.47c1.27-0.86,2.62-0.87,3.91-0.05
				c2.43,1.55,4.84,3.14,7.26,4.71c1.28,0.83,2.57,1.65,3.83,2.51c0.45,0.3,0.83,0.35,1.36,0.2c2.49-0.73,4.95-1.68,7.49-2.03
				c1.15-0.16,4.54-0.44,5.11-0.44c2.23-0.01,4.47-0.01,6.7-0.01c3.87-0.03,7.74,0,11.61,0.01c0.01,0,0.02,0,0.03,0h0.01
				c5.17,0.01,8.72,3.6,8.72,8.79c0,8.4,0.01,16.79,0,25.19c0,0.71-0.17,1.35-0.46,1.89c-0.01,0.01-0.01,0.03-0.02,0.04
				c-0.05,0.09-0.1,0.17-0.16,0.26c-0.02,0.03-0.04,0.05-0.06,0.08c-0.05,0.07-0.1,0.14-0.15,0.2c-0.03,0.03-0.05,0.06-0.08,0.09
				c-0.05,0.06-0.1,0.11-0.16,0.16c-0.03,0.03-0.06,0.06-0.09,0.09c-0.06,0.05-0.12,0.1-0.18,0.14c-0.03,0.03-0.06,0.05-0.09,0.07
				c-0.07,0.05-0.14,0.1-0.21,0.14c-0.02,0.01-0.05,0.03-0.07,0.04c-0.09,0.05-0.18,0.1-0.28,0.14c-0.01,0.01-0.02,0.01-0.03,0.01
				c-0.45,0.2-0.95,0.31-1.49,0.31c-0.13,0-0.27-0.01-0.4-0.03c0,0-0.01,0-0.01,0c-0.02,0-0.04-0.01-0.07-0.01
				c-0.06-0.01-0.12-0.01-0.18-0.03c-1.61-0.28-2.81-1.65-2.85-3.45c-0.04-1.98-0.03-3.95,0-5.93c0-4.29,0-8.58,0-12.87
				c-0.01-2.25,0-4.49-0.01-6.74c0-0.07,0-0.14-0.01-0.2c-0.07-0.77-0.35-1.06-1.2-1.26c-0.08-0.01-0.16-0.03-0.25-0.04v0.99
				c0,16.34-0.01,32.68,0.01,49.02c0,0.02,0,0.03,0,0.05c0,0.35,0,0.69,0,1.04l0,0C94.02,119.43,94.02,121.74,94.02,124.05z
				 M45.74,61.8c2.08,1.35,4.1,2.67,6.32,4.11c-1.49,0.33-1.35,1.38-1.35,2.4c0.01,8.05,0.01,16.09,0,24.14
				c0,0.57-0.04,1.17-0.19,1.71c-0.48,1.63-2.08,2.6-3.84,2.39c-1.6-0.19-2.88-1.57-3-3.21c-0.01-0.17-0.01-0.35-0.01-0.52
				c0-8.48,0-16.96,0-25.44C43.66,65.29,44.33,63.45,45.74,61.8z M64.8,55.82c-0.01,0-0.02,0.01-0.02,0.01
				c-0.08,0.04-0.17,0.08-0.25,0.12c-0.03,0.01-0.06,0.02-0.09,0.04c-0.06,0.03-0.12,0.05-0.19,0.08c-0.06,0.02-0.12,0.05-0.18,0.07
				c-0.03,0.01-0.05,0.02-0.08,0.03c-0.8,0.28-1.65,0.42-2.53,0.41c-0.6-0.01-1.19-0.09-1.75-0.25c-0.2-0.07-0.41-0.13-0.61-0.2
				c-2.91-1.06-4.77-3.81-4.78-6.84c0.02-2.81,1.56-5.31,4.07-6.53c0.01,0,0.02-0.01,0.02-0.01c0.08-0.04,0.17-0.08,0.26-0.12
				c0.03-0.01,0.06-0.03,0.09-0.04c0.06-0.03,0.12-0.05,0.19-0.08c0.06-0.02,0.12-0.05,0.18-0.07c0.03-0.01,0.05-0.02,0.08-0.03
				c0.79-0.27,1.65-0.42,2.53-0.41c0.6,0.01,1.19,0.09,1.75,0.24c0.21,0.07,0.41,0.13,0.61,0.21c2.91,1.06,4.77,3.81,4.78,6.84
				C68.86,52.1,67.31,54.59,64.8,55.82z M87.82,55.82c-0.01,0-0.02,0.01-0.02,0.01c-0.08,0.04-0.17,0.08-0.26,0.12
				c-0.03,0.01-0.06,0.02-0.09,0.04c-0.06,0.03-0.12,0.05-0.19,0.08c-0.06,0.02-0.12,0.05-0.18,0.07c-0.03,0.01-0.05,0.02-0.08,0.03
				c-0.8,0.28-1.65,0.42-2.53,0.41c-0.6-0.01-1.19-0.09-1.75-0.25c-0.2-0.07-0.41-0.13-0.61-0.2c-2.91-1.06-4.77-3.81-4.78-6.84
				c0.02-2.81,1.56-5.31,4.07-6.53c0.01,0,0.02-0.01,0.02-0.01c0.09-0.04,0.17-0.08,0.26-0.12c0.03-0.01,0.06-0.03,0.09-0.04
				c0.06-0.03,0.12-0.05,0.19-0.08c0.06-0.02,0.12-0.05,0.18-0.07c0.03-0.01,0.05-0.02,0.08-0.03c0.8-0.27,1.65-0.42,2.53-0.41
				c0.6,0.01,1.19,0.09,1.75,0.24c0.2,0.07,0.41,0.13,0.61,0.21c2.91,1.06,4.77,3.81,4.78,6.84C91.87,52.1,90.33,54.59,87.82,55.82z
				"
            />
            <path
              className="st0"
              d="M85.54,116.9C85.54,116.9,85.54,116.9,85.54,116.9c0.02-7.7-0.01-15.4-0.01-23.1v-1.07H83.7v13.45
				c0,3.28,0,6.57,0,9.85h0.01c0,2.16,0,5.93-0.01,8.02h1.85C85.54,122,85.54,119.01,85.54,116.9z"
            />
            <path
              className="st0"
              d="M62.53,117.28c0,0-0.01,0-0.01,0c0.02-7.83-0.01-15.67-0.01-23.5v-1.05h-1.82v13.45c0,2.95,0,13.52,0,17.87
				h1.84C62.53,122.12,62.53,119.36,62.53,117.28z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
